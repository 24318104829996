import { createRouter, createWebHistory } from 'vue-router'
import index from '../views/index.vue'
import platform from '../views/platform.vue'
import partner from '../views/partner.vue'
const routes = [
    {
        path: '/',
        name: 'index',
        component: index
    },
    {
        path: '/platform',
        name: 'platform',
        component: platform
    },
    {
        path: '/partner',
        name: 'partner',
        component: partner
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
export default router
